$primary: #032259;
$secondary: #b7924c;
$tertiary: #f4f4f4;

$blue_1: #07388d;

$gray_1: #8c8c8c;
$gray_2: #b0b0b0;
$gray_3: #7b7b7b;
$gray_4: #7f7f7f;

$gray_transparent: rgb(222 222 222 / 46%);
$white_1: #fff;
$white_2: #f0f0f0;
$white_3: #dfdddd;
$white_4: #fafafa;
$white_5: #f4f4f4;
$white_6: #c2c1c1;

$shadow: 1px 3px 9px -6px;
