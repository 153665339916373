.auth {
  min-height: 100vh;
  background: url("../img/auth-background.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 450px) {
    .ant-card .ant-card-body {
      padding: 10px;
    }
  }

  &::after {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 30%) !important;
    z-index: 1;
  }

  &::before {
    content: "  ";
    height: 0.1px;
    display: block;
  }

  .container {
    background: transparent;
    width: 90%;
    max-width: 50rem;
    border: 2px solid $white_1;
    border-radius: 1px;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
    text-align: center;
    padding: 2%;

    .inside {
      background: rgb(0 0 0 / 38%);
      border-radius: 1px;
      text-align: center;
      height: 100vh;
      max-height: 22rem;
      padding-top: 4rem;

      @media (max-width: 400px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    @media (max-width: 600px) {
      max-width: 95%;
    }
  }

  .login {
    padding: 1rem;

    .ant-form {
      .ant-form-item {
        .ant-form-item-row .ant-form-item-label .ant-form-item-required,
        .ant-form-item-explain-error {
          color: $white_1 !important;

          &::before {
            color: $white_1 !important;
          }
        }

        .ant-input {
          background: transparent;
          color: $white_1;
          border-color: $white_1;
        }
      }
    }

    @media (max-width: 600px) {
      padding: 1rem 2rem;
      display: contents;
    }
  }
}

.admin {
  min-height: 100vh;

  .ant-layout-sider {
    z-index: 2;
  }

  .container-admin {
    padding: 24px;
    min-height: 360px;
    max-width: 81rem;
    margin: auto;

    @media (max-width: 500px) {
      padding: 0;
    }
  }

  .ant-layout-header {
    background: $secondary !important;
  }

  .ant-layout-content {
    background: $white_1 !important;
    padding: 24px 0 24px 0;

    .ant-card {
      max-width: 80rem;
      box-shadow: $shadow;
      background: $white_4;

      .ant-card-head {
        .ant-card-head-title {
          font-size: 20px;
        }
      }
    }

    .ant-card.noShadown {
      max-width: 80rem;
      box-shadow: none;
      background: transparent !important;
    }

    @media (max-width: 500px) {
      padding: 24px;
    }
  }

  .card-project {
    max-width: 12rem !important;
    margin-bottom: 1rem !important;

    .ant-card-body {
      padding: 28px 15px 5px 15px;
      min-height: 16rem;

      .ant-row {
        line-height: 35px;
      }

      .actions {
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align: center;

        @media (max-width: 720px) {
          width: 165px;
        }
      }
    }

    @media (max-width: 720px) {
      max-width: 11rem !important;
    }
  }

  .ant-typography {
    &.text {
      font-size: 11px;
      margin-bottom: 5px;
      margin-top: -7px;
    }

    &.text_1 {
      font-size: 11px;
      margin-bottom: 0;
      margin-top: -4px;
      line-height: 17px;
    }
  }

  .logo-mobile {
    width: 100%;
    max-width: 218px;
    position: absolute;
    left: 17px;
    top: 45px;
    transition: all 1s;
    z-index: 1;

    @media (max-width: 575px) {
      max-width: 170px;
      top: 5px;
      left: 5px;
    }
  }

  .leftNav {
    background: $primary;

    .ant-menu-item,
    .ant-menu-submenu {
      border-bottom: 1px solid;
      border-radius: 0;
      max-width: 225px;
      margin: 13px auto;
      padding: 0 12px !important;

      .ant-menu-submenu-title {
        padding: 0 !important;

        &:active {
          background: transparent !important;
        }
      }

      &.ant-menu-item-selected,
      &.ant-menu-submenu-selected {
        background: $secondary;
        border-radius: 5px;
        border-bottom: 0;

        &:hover {
          background: $secondary !important;
        }
      }

      &.ant-menu-submenu-open {
        &:hover {
          background: transparent !important;
        }
      }
    }

    .ant-menu-sub,
    .ant-menu-submenu-open {
      background: $primary !important;
      transition: none;
    }
    .ant-menu-item-only-child {
      border: none;
      border-radius: 10px;
      background: #ffffff0a;
      margin-left: 0;
      margin-right: 0;
      width: 100%;

      &:hover {
        background: $blue_1 !important;
      }
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-item,
      .ant-menu-submenu {
        text-align: center;
      }
      .ant-menu-submenu-selected.ant-menu-submenu-open {
        &,
        &:hover {
          background: $secondary !important;
        }
      }
      .ant-menu-submenu-title {
        &:hover {
          background: transparent;
        }
      }
      .ant-menu-title-content {
        display: none !important;
      }
    }

    .ant-layout-sider-zero-width-trigger {
      background: $primary !important;
      top: 23rem;
      width: 25px;
      height: 35px;
      inset-inline-end: -25px;

      .trigger {
        font-size: 17px;
        padding: 1px;
      }
    }

    @media (max-width: 575px) {
      &:not(.ant-menu-inline-collapsed) {
        position: absolute;
        height: 200vh;
        z-index: 2;
      }
    }
  }

  .ant-layout-header {
    min-height: 9rem;

    .actions {
      float: right;
      margin-right: 20px;
      margin-top: 45px;

      button {
        font-size: 16px;
      }

      @media (max-width: 785px) {
        margin-top: 35px;
      }
    }
  }
}

.input-search {
  position: absolute;
  top: -130px;
  right: 200px;
  min-width: 30%;
  transition: all 1s;

  @media (min-width: 1690px) {
    right: 200px;
  }

  @media (max-width: 1690px) {
    right: 280px;
  }
  @media (max-width: 785px) {
    top: -95px;
    right: -25px;
  }
  @media (max-width: 500px) {
    top: -70px;
    right: 5px;
  }
}

.card-project-info {
  border: none !important;
  width: 100%;

  .ant-card-head {
    padding: 0;
    min-height: unset;
    margin-bottom: 0;

    .ant-col {
      text-align: center;
      border-color: $secondary !important;
      border-bottom: 2px solid;
      font-size: 13px;
      line-height: 22px;
      background-color: #f4f4f4;
      padding: 5px;
      cursor: pointer;

      &.begin {
        border-top-left-radius: 10px;
      }

      &.middle {
        border-left: 2px solid;
        border-right: 2px solid;

        @media (max-width: 575px) {
          border-right: none;
          border-top-right-radius: 10px;
        }
      }

      &.end {
        border-top-right-radius: 10px;

        &.actived {
          @media (max-width: 575px) {
            border-top-right-radius: 0;
          }
        }
      }

      &.actived {
        background-color: $secondary;
        color: white;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.bg-gray {
  background-color: $white_5;
}

.align-center {
  text-align: center;
}

.table-allunox {
  .ant-table-thead tr th {
    text-align: center;
  }
  .ant-table-tbody {
    tr td {
      text-align: center;
      border-bottom: 0 !important;
      border-top: 0 !important;
      border-radius: 0 !important;

      .input-number {
        width: 100%;
        max-width: 200px;
        border: 1px solid;
        border-radius: 0;
        border-color: $gray_2;
        padding: 4px;
      }
    }
    tr {
      td {
        background: transparent;
      }

      &:hover {
        td {
          background: transparent;
        }
      }
    }

    tr.even {
      td {
        background: $white_3;
        border-bottom: 0 !important;
        border-top: 0 !important;

        &.bx-gray {
          border-right: 2px solid;
          border-left: 2px solid;
          border-color: $gray_1;
        }
        &.br-gray {
          border-right: 2px solid;
          border-color: $gray_1;
        }
      }
      &:hover {
        td {
          background: $white_3;
        }
      }
    }
  }
}

.form-allunox {
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: $gray_1;
    background-color: $gray_1;
  }

  .radio-custom {
    padding-top: 20px;

    .ant-radio-wrapper {
      .ant-radio {
        display: none;
      }

      &.ant-radio-wrapper-checked {
        span:nth-child(1) {
          border: 3px solid $primary;
        }
      }
    }
  }

  .ant-input-number {
    height: auto !important;
  }

  .ant-select-selector {
    height: 44px !important;

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 20px;
    }
  }

  textarea.ant-input,
  input.ant-input,
  .ant-input-number,
  .ant-select-selector {
    background-color: $white_4 !important;
    border-radius: 14px !important;
    padding: 10px !important;
    width: 100%;

    .ant-input-number-handler-wrap {
      margin-right: 5px;

      span {
        background: #fafafa;
      }
    }

    .ant-input-number-input-wrap input {
      height: auto;
      vertical-align: baseline;
    }
  }

  .form-control.ant-input {
    padding-left: 48px !important;
  }

  .react-tel-input .flag-dropdown.open .selected-flag {
    background: $white_2 !important;
    border-radius: 14px 0 0 14px !important;
  }

  .react-tel-input .flag-dropdown .selected-flag {
    border: none;
    border-radius: 14px 0 0 14px !important;
    &:hover {
      border: none;
    }
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
    .ant-checkbox-checked:after {
      border-color: $gray_1;
    }
    .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
      background: $white_2;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $white_2 !important;
  }

  .ant-checkbox-wrapper {
    align-items: end;
    margin-left: 10px;
    &:hover {
      .ant-checkbox .ant-checkbox-inner {
        background-color: $white_2;
      }
    }

    .ant-checkbox {
      .ant-checkbox-inner {
        width: 35px;
        height: 35px;
        border: 3px solid $gray_1;
        border-radius: 1px;

        &.ant-checkbox-checked {
          background-color: $white_2;
        }

        &::after {
          width: 8.714286px;
          height: 15.142857px;
          top: 45%;
          inset-inline-start: 28.5%;
          border-color: $gray_1;
        }
      }
    }
  }
}

.accordeon-allunox {
  margin: 30px;

  .ant-collapse-item {
    background: $white_3;
    box-shadow: none;
    transition: all 0.5s;

    .ant-collapse-header-text .ant-typography {
      color: $blue_1;
    }

    &.ant-collapse-item-active {
      background: $white_1 !important;
    }

    &.first {
      border-radius: 10px 10px 0 0 !important;
    }

    &.last {
      border-radius: 0 0 10px 10px !important;
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;

        .table-balcony {
          .ant-table.ant-table-small {
            .ant-table-thead > tr {
              th {
                text-align: center;
                border-top: 1px solid #f4f4f4;
                border-bottom: 1px 1px solid #f4f4f4;
              }

              th {
                background: transparent;
                font-weight: normal;

                border-radius: 0;
                &::before {
                  display: none;
                }
              }
            }

            .ant-table-tbody > tr {
              td {
                text-align: center;
                border-top: 1px solid #f4f4f4;
                border-bottom: 1px 1px solid #f4f4f4;
              }
            }
          }
        }
      }
    }
  }
}

.custom-action {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.custom-upload .ant-upload {
  height: auto !important;
  min-height: 80px;
}
