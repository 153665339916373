.checkbox-allunox {
  &.ant-checkbox-wrapper {
    align-items: end;
    margin-left: 10px;
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        background-color: $white_1 !important;
        border-radius: 4px !important;
        border-color: $gray_1 !important;

        &:hover,
        &:active {
          border-color: $gray_1 !important;
        }

        &::after {
          width: 5.714286px;
          height: 10.142857px;
          top: 45%;
          inset-inline-start: 28.5%;
          border-color: $gray_1;
        }
      }
    }
  }
}
