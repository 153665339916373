@import url("./static/scss/_index.scss");
@import url("react-phone-input-2/lib/plain.css");

html,
body {
  background: white;
  margin: 0;
  min-height: 100%;
  height: 100%;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.text-white {
  color: white !important;
}

.ml-2 {
  margin-left: 2rem;
}
.mr-2 {
  margin-right: 2rem;
}

.p-0 {
  padding: 0 !important;
}
.m-o {
  margin: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}

.ant-form-item-has-error {
  .react-tel-input .form-control.ant-input,
  .flag-dropdown .selected-flag {
    border-color: red !important;
  }
}

.react-tel-input {
  .form-control.ant-input {
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }
  .flag-dropdown {
    border: none;

    .selected-flag {
      background: transparent !important;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      &:active,
      &:hover {
        background: transparent !important;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
      }
    }
  }
}

.spin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
}

.td-edit-input {
  max-width: 150px;
  margin: auto !important;
}

.td-edit {
  border: 1px solid;
  border-color: transparent;

  &:hover {
    border-color: #eceaea;
  }
}

.custom-collapse .ant-collapse-header {
  background: #f2f2f2;
  border-radius: 4px;
}

.inline {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer;
}
