.ant-btn-primary {
  background-color: $primary;

  &:hover {
    background: $primary !important;
    opacity: 0.9;
  }
}

.btn-link {
  color: #1677ff;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    opacity: 0.6;
  }
}

.btn-default-allunox {
  margin-right: 10px;
  border: 2px solid;
  border-color: $gray_4;
  color: $gray_3;
}

.btn-add-project {
  text-align: center;
  max-width: 150px;
  margin: auto;
  color: $white_6;
  font-size: 16px;
  cursor: pointer;

  .card-btn {
    background: $white_6;
    text-align: center;
    max-width: 120px;
    padding: 20px;
    margin: auto;
    border-radius: 10px;
    font-size: 48px;
    margin-bottom: 10px;
    margin-top: 3rem;

    svg {
      color: $white_1;
    }
  }

  &:hover {
    opacity: 0.7;
  }
}
