.table-responsive .ant-table-content {
    display: block;
    width: 100%;
    overflow-x: auto;
}

.table-responsive .ant-table-content .ant-table-cell {
    white-space: nowrap;
}

.table-actions {
    text-align: center;
    .color-primary {
        color: $primary;
    }
}
